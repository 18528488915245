<template>
  <div class="m-bottom" :class="['m-bottom--' + justify, type == 'actions' ? 'm-bottom--actions' : '']">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  props: {
    justify: {
      type: String,
      default: 'space-between'
    },
    type: String,
  },
};
</script>

<style scoped lang="scss">
  .m-bottom {
    @include flex(space-between, center, nowrap);

    &--space-between {
      justify-content: space-between;
    }

    &--end {
      justify-content: flex-end;
    }
    
    &--actions {
      flex-wrap: wrap;
      
      .e-button {
        margin-bottom: 20px;
        width: 100%;
        
        @media #{$sm} {
          margin-bottom: 0;
          width: 160px;
        }
        
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

</style>
